(function () {
    'use strict';

    angular.module('aerosApp')
        .controller('BasicToolController', BasicToolController);

    BasicToolController.$inject = ['$rootScope', '$filter', '$state', 'userInfo', 'storage', 'routesConstant',
        'PageService'];

    function BasicToolController($rootScope, $filter, $state, userInfo, storage, routesConstant, PageService) {
        var vm = this;
        var CUSTOMER = routesConstant.CUSTOMER;

        if (typeof storage.get('org_selected_tool') !== 'undefined') {
            $state.go(CUSTOMER.ORGANIZATION_TOOLS_TYPE_TOOLLIBRARIES.stateName,
                {toolType: storage.get('org_selected_tool')});
        }

        vm.toolType = storage.get('org_selected_tool') || $state.params.toolType;
        vm.toolTypes = userInfo.organization.features;

        PageService.setTitle('Organization Tools');

        vm.updateSelectedTool = function () {
            storage.set('org_selected_tool', vm.toolType);
            $state.go(CUSTOMER.ORGANIZATION_TOOLS_TYPE_TOOLLIBRARIES.stateName, {toolType: vm.toolType});
        };
    }
})();
